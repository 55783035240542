/*
 *
 *  Moon
 *
 *  Designer: Taylan Tatlı
 *  WEB: http://taylantatli.me
 *
*/

// Partials
@import "variables";
@import "mixins";
@import "normalize";
@import "site";
@import "typography";
@import "elements";
@import "syntax";
@import "nav";
@import "vendor/fonts";
@import "https://use.fontawesome.com/releases/v6.5.2/css/all.css";
@import "vendor/animate";
@import "vendor/magnific-popup/magnific-popup";
@import "print";

.form-control {
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
