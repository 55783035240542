// Fira Sans
@font-face{
    font-family: 'Fira Sans';
    src: url("../fonts/FiraSans/eot/FiraSans-Light.eot");
    src: local("Fira Sans Light"),
         url("../fonts/FiraSans/eot/FiraSans-Light.eot") format("embedded-opentype"),
         url("../fonts/FiraSans/woff/FiraSans-Light.woff") format("woff"),
         url("../fonts/FiraSans/woff2/FiraSans-Light.woff2") format("woff2"),
         url("../fonts/FiraSans/ttf/FiraSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face{
    font-family: 'Fira Sans';
    src: url("../fonts/FiraSans/eot/FiraSans-LightItalic.eot");
    src: local("Fira Sans Light Italic"),
         url("../fonts/FiraSans/eot/FiraSans-LightItalic.eot") format("embedded-opentype"),
         url("../fonts/FiraSans/woff/FiraSans-LightItalic.woff") format("woff"),
         url("../fonts/FiraSans/woff2/FiraSans-LightItalic.woff2") format("woff2"),
         url("../fonts/FiraSans/ttf/FiraSans-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face{
    font-family: 'Fira Sans';
    src: url("../fonts/FiraSans/eot/FiraSans-Regular.eot");
    src: local("Fira Sans Regular"),
         url("../fonts/FiraSans/eot/FiraSans-Regular.eot") format("embedded-opentype"),
         url("../fonts/FiraSans/woff/FiraSans-Regular.woff") format("woff"),
         url("../fonts/FiraSans/woff2/FiraSans-Regular.woff2") format("woff2"),
         url("../fonts/FiraSans/ttf/FiraSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face{
    font-family: 'Fira Sans';
    src: url("../fonts/FiraSans/eot/FiraSans-Italic.eot");
    src: local("Fira Sans Regular Italic"),
         url("../fonts/FiraSans/eot/FiraSans-Italic.eot") format("embedded-opentype"),
         url("../fonts/FiraSans/woff/FiraSans-Italic.woff") format("woff"),
         url("../fonts/FiraSans/woff2/FiraSans-Italic.woff2") format("woff2"),
         url("../fonts/FiraSans/ttf/FiraSans-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face{
    font-family: 'Fira Sans';
    src: url("../fonts/FiraSans/eot/FiraSans-Medium.eot");
    src: local("Fira Sans Medium"),
         url("../fonts/FiraSans/eot/FiraSans-Medium.eot") format("embedded-opentype"),
         url("../fonts/FiraSans/woff/FiraSans-Medium.woff") format("woff"),
         url("../fonts/FiraSans/woff2/FiraSans-Medium.woff2") format("woff2"),
         url("../fonts/FiraSans/ttf/FiraSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face{
    font-family: 'Fira Sans';
    src: url("../fonts/FiraSans/eot/FiraSans-MediumItalic.eot");
    src: local("Fira Sans Medium Italic"),
         url("../fonts/FiraSans/eot/FiraSans-MediumItalic.eot") format("embedded-opentype"),
         url("../fonts/FiraSans/woff/FiraSans-MediumItalic.woff") format("woff"),
         url("../fonts/FiraSans/woff2/FiraSans-MediumItalic.woff2") format("woff2"),
         url("../fonts/FiraSans/ttf/FiraSans-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face{
    font-family: 'Fira Sans';
    src: url("../fonts/FiraSans/eot/FiraSans-Bold.eot");
    src: local("Fira Sans Bold"),
         url("../fonts/FiraSans/eot/FiraSans-Bold.eot") format("embedded-opentype"),
         url("../fonts/FiraSans/woff/FiraSans-Bold.woff") format("woff"),
         url("../fonts/FiraSans/woff2/FiraSans-Bold.woff2") format("woff2"),
         url("../fonts/FiraSans/ttf/FiraSans-Bold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face{
    font-family: 'Fira Sans';
    src: url("../fonts/FiraSans/eot/FiraSans-BoldItalic.eot");
    src: local("Fira Sans Bold Italic"),
         url("../fonts/FiraSans/eot/FiraSans-BoldItalic.eot") format("embedded-opentype"),
         url("../fonts/FiraSans/woff/FiraSans-BoldItalic.woff") format("woff"),
         url("../fonts/FiraSans/woff2/FiraSans-BoldItalic.woff2") format("woff2"),
         url("../fonts/FiraSans/ttf/FiraSans-BoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

// Inconsolata
// latin-ext
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local('Inconsolata'), url(https://fonts.gstatic.com/s/inconsolata/v12/BjAYBlHtW3CJxDcjzrnZCCYE0-AqJ3nfInTTiDXDjU4.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
// latin 
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local('Inconsolata'), url(https://fonts.gstatic.com/s/inconsolata/v12/BjAYBlHtW3CJxDcjzrnZCI4P5ICox8Kq3LLUNMylGO4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
// latin-ext
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  src: local('Inconsolata Bold'), local('Inconsolata-Bold'), url(https://fonts.gstatic.com/s/inconsolata/v12/AIed271kqQlcIRSOnQH0yejkDdvhIIFj_YMdgqpnSB0.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
// latin 
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  src: local('Inconsolata Bold'), local('Inconsolata-Bold'), url(https://fonts.gstatic.com/s/inconsolata/v12/AIed271kqQlcIRSOnQH0yYlIZu-HDpmDIZMigmsroc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
